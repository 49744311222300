// src/components/HeroSection.js
import React from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';

AOS.init();

function HeroSection() {
  return (
    <section className="hero text-center py-5">
      <div className="container">
        <h2 data-aos="fade-up">Explora el Futuro de la Tecnología Móvil</h2>
        <p data-aos="fade-up" data-aos-delay="100">Descubre nuestras apps innovadoras en Play Store</p>
        <a href="#apps" className="btn-hero" data-aos="fade-up" data-aos-delay="200">Ver Apps</a>
      </div>
    </section>
  );
}

export default HeroSection;
