// src/components/AppsSection.js
import React from 'react';

function AppsSection() {
  return (
    <section id="apps" className="container py-5">
      <h2 className="text-center" data-aos="zoom-in">Nuestras Aplicaciones</h2>
      <div className="row">
        {/* App 1 */}
        <div className="col-md-4" data-aos="zoom-in">
          <div className="card">
            <img src="/img/calculos-electricos-pro.png" alt="Cálculos Eléctricos Pro" />
            <h3>Cálculos Eléctricos Pro</h3>
            <p>La herramienta definitiva para cálculos eléctricos profesionales.</p>
            <a href="https://play.google.com/store/apps/details?id=com.benyaminsdeveloper.ohmslawpro" className="btn-store" target="_blank" rel="noreferrer">
              <i className="fab fa-google-play"></i> Ver en Play Store
            </a>
          </div>
        </div>
        {/* App 2 */}
        <div className="col-md-4" data-aos="zoom-in" data-aos-delay="200">
          <div className="card">
            <img src="/img/electrical-calculations-pro.png" alt="Electrical Calculations Pro" />
            <h3>Electrical Calculations Pro</h3>
            <p>Advanced electrical calculations for professionals.</p>
            <a href="https://play.google.com/store/apps/details?id=com.benyaminsdeveloper.ohmslawusapro" className="btn-store" target="_blank" rel="noreferrer">
              <i className="fab fa-google-play"></i> Ver en Play Store
            </a>
          </div>
        </div>
        {/* App 3 */}
        <div className="col-md-4" data-aos="zoom-in" data-aos-delay="400">
          <div className="card">
            <img src="/img/calculos-electricos.png" alt="Cálculos Eléctricos" />
            <h3>Cálculos Eléctricos</h3>
            <p>Calculadora de cálculos eléctricos para usuarios generales.</p>
            <a href="https://play.google.com/store/apps/details?id=com.benyaminsdeveloper.ohmslaw&hl=es" className="btn-store" target="_blank" rel="noreferrer">
              <i className="fab fa-google-play"></i> Ver en Play Store
            </a>
          </div>
        </div>
        {/* App 4 */}
        <div className="col-md-4" data-aos="zoom-in" data-aos-delay="600">
          <div className="card">
            <img src="/img/crazyup.png" alt="CrazyUP" />
            <h3>CrazyUP</h3>
            <p>Un juego adictivo que desafía tus habilidades.</p>
            <a href="https://play.google.com/store/apps/details?id=net.DItoXsoft.CrazyUp&hl=es" className="btn-store" target="_blank" rel="noreferrer">
              <i className="fab fa-google-play"></i> Ver en Play Store
            </a>
          </div>
        </div>
        {/* App 5 */}
        <div className="col-md-4" data-aos="zoom-in" data-aos-delay="800">
          <div className="card">
            <img src="/img/electrical-calculations.png" alt="Electrical Calculations" />
            <h3>Electrical Calculations</h3>
            <p>Simple tool for electrical calculations.</p>
            <a href="https://play.google.com/store/apps/details?id=com.benyaminsdeveloper.ohmslawusa&hl=es" className="btn-store" target="_blank" rel="noreferrer">
              <i className="fab fa-google-play"></i> Ver en Play Store
            </a>
          </div>
        </div>
        {/* App 6 */}
        <div className="col-md-4" data-aos="zoom-in" data-aos-delay="1000">
          <div className="card">
            <img src="/img/magic-number.png" alt="Magic Number" />
            <h3>Magic Number</h3>
            <p>Una app que te sorprende con números mágicos.</p>
            <a href="https://play.google.com/store/apps/details?id=com.benyaminsdeveloper.magicnumberpro&hl=es" className="btn-store" target="_blank" rel="noreferrer">
              <i className="fab fa-google-play"></i> Ver en Play Store
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AppsSection;
