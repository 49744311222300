// src/components/FeaturesSection.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt, faShieldAlt, faMobileAlt } from '@fortawesome/free-solid-svg-icons';

function FeaturesSection() {
  return (
    <section id="features" className="bg-light py-5">
      <div className="container">
        <h2 className="text-center" data-aos="fade-up">Características Principales</h2>
        <div className="row text-center">
          <div className="col" data-aos="fade-up" data-aos-delay="100">
            <FontAwesomeIcon icon={faBolt} size="3x" style={{ color: 'yellow' }} />
            <h3>Velocidad</h3>
            <p>Nuestras apps están optimizadas para brindar la mejor experiencia.</p>
          </div>
          <div className="col" data-aos="fade-up" data-aos-delay="200">
            <FontAwesomeIcon icon={faShieldAlt} size="3x" style={{ color: 'blue' }} />
            <h3>Seguridad</h3>
            <p>Protegemos tus datos y brindamos máxima seguridad.</p>
          </div>
          <div className="col" data-aos="fade-up" data-aos-delay="300">
            <FontAwesomeIcon icon={faMobileAlt} size="3x" style={{ color: 'green' }} />
            <h3>Compatibilidad</h3>
            <p>Disponibles en múltiples dispositivos Android.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturesSection;
