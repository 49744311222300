// src/components/Footer.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faYoutube, faTiktok } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="bg-dark text-light text-center py-3">
      <p>&copy; 2024 DitoXsoft. Todos los derechos reservados.</p>
      <p>
        <a href="terms.html" className="text-light">Términos y Condiciones</a> |{' '}
        <a href="privacy.html" className="text-light">Política de Privacidad</a>
      </p>
      <div className="social-icons">
        <a href="https://www.facebook.com/tu-perfil" target="_blank" rel="noopener noreferrer" className="facebook-icon mx-2">
          <FontAwesomeIcon icon={faFacebook} size="2x" />
        </a>
        <a href="https://www.youtube.com/@DitoXsoft/null" target="_blank" rel="noopener noreferrer" className="youtube-icon mx-2">
          <FontAwesomeIcon icon={faYoutube} size="2x" />
        </a>
        <a href="https://www.tiktok.com/@ditoxsoft" target="_blank" rel="noopener noreferrer" className="tiktok-icon mx-2">
          <FontAwesomeIcon icon={faTiktok} size="2x" />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
