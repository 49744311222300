// src/components/Header.js
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function Header() {
  return (
    <header className="container-fluid bg-dark text-light py-3">
      <div className="container d-flex justify-content-between align-items-center">
        <h1 className="logo">DitoXsoft</h1>
        <nav>
          <ul className="nav">
            <li className="nav-item"><a href="#apps" className="nav-link text-light">Apps</a></li>
            <li className="nav-item"><a href="#features" className="nav-link text-light">Características</a></li>
            <li className="nav-item"><a href="#testimonials" className="nav-link text-light">Reseñas</a></li>
            <li className="nav-item"><a href="#contact" className="nav-link text-light">Contacto</a></li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
