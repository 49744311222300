// src/components/TestimonialsSection.js
import React from 'react';

function TestimonialsSection() {
  return (
    <section id="testimonials" className="container py-5">
      <h2 className="text-center" data-aos="zoom-in">Lo que dicen nuestros usuarios</h2>
      <div className="row text-center">
        <div className="col" data-aos="fade-up" data-aos-delay="100">
          <blockquote>“Increíbles apps, realmente mejoraron mi productividad.”</blockquote>
          <p>- Osv****</p>
        </div>
        <div className="col" data-aos="fade-up" data-aos-delay="200">
          <blockquote>“Gran diseño y funcionalidad. Muy recomendadas.”</blockquote>
          <p>- Nat****</p>
        </div>
        <div className="col" data-aos="fade-up" data-aos-delay="300">
          <blockquote>“El soporte es excelente y las apps son muy útiles.”</blockquote>
          <p>- Dar****</p>
        </div>
      </div>
    </section>
  );
}

export default TestimonialsSection;
