import React from 'react';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'aos/dist/aos.css';
import AOS from 'aos';
import 'aos/dist/aos.js';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import AppsSection from './components/AppsSection';
import FeaturesSection from './components/FeaturesSection';
import TestimonialsSection from './components/TestimonialsSection';
import ContactSection from './components/ContactSection';
import Footer from './components/Footer';

AOS.init();

function App() {
  return (
    <div>
      <Header />
      <HeroSection />
      <AppsSection />
      <FeaturesSection />
      <TestimonialsSection />
      <ContactSection />
      <Footer />
    </div>
  );
}

export default App;