// src/components/ContactSection.js

import React from 'react';

const ContactSection = () => {
  return (
    <section id="contact" className="bg-dark text-light text-center py-5">
      <h2 data-aos="fade-up">Contáctanos</h2>
      <p data-aos="fade-up" data-aos-delay="100">
        Para más información sobre nuestras apps, no dudes en contactarnos.
      </p>
      <a
        href="mailto:customer.ditoxsoft.developer@gmail.com"
        className="btn btn-light btn-lg"
        data-aos="fade-up"
        data-aos-delay="200"
      >
        Enviar Email
      </a>
    </section>
  );
}

export default ContactSection;
